import React from "react";

import AuditTable from "../../components/Audit/AuditTable";

export default function Audit() {
  return (
      <>
          <div className="flex flex-wrap mt-4">
              <div className="w-full mb-12 px-4">
                  <AuditTable />
              </div>
          </div>
      </>
  );
}
