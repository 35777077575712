import React from "react";

import WebhooksTable from "../../components/Webhooks/WebhooksTable";

export default function Webhooks() {
  return (
      <>
          <div className="flex flex-wrap mt-4">
              <div className="w-full mb-12 px-4">
                  <WebhooksTable />
              </div>
          </div>
      </>
  );
}
