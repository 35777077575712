import React from "react";

import UserGroupTable from "../../components/User/UserGroupTable";

export default function UserGroups() {
  return (
      <>
          <div className="flex flex-wrap mt-4">
              <div className="w-full mb-12 px-4">
                  <UserGroupTable />
              </div>
          </div>
      </>
  );
}
