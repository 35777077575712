import React from "react";

import StatusTable from "../../components/Status/StatusTable";

export default function Status() {
  return (
      <>
          <div className="flex flex-wrap mt-4">
              <div className="w-full mb-12 px-4">
                  <StatusTable />
              </div>
          </div>
      </>
  );
}
