import React from "react";

import DeadLetterTable from "../../components/DeadLetter/DeadLetterTable";

export default function DeadLetter() {
  return (
      <>
          <div className="flex flex-wrap mt-4">
              <div className="w-full mb-12 px-4">
                  <DeadLetterTable />
              </div>
          </div>
      </>
  );
}
