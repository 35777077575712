import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Status from "views/admin/Status.js";
import Audit from "../views/admin/Audit";
import DeadLetter from "../views/admin/DeadLetter";
import Webhooks from "../views/admin/Webhooks";
import Users from "../views/admin/Users";
import UserGroups from "../views/admin/UserGroups";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100 h-full">
        <AdminNavbar />
        <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12"/>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/status" exact component={Status} />
            <Route path="/admin/deadletter" exact component={DeadLetter} />
            <Route path="/admin/audit" exact component={Audit} />
            <Route path="/admin/webhooks" exact component={Webhooks} />
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/usergroups" exact component={UserGroups} />
            <Redirect from="/admin" to="/admin/status" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
