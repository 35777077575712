import React from "react";
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import 'react-toastify/dist/ReactToastify.css';

import App from "app.js";

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
