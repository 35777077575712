import React, {Component} from "react";
import PropTypes from "prop-types";
import {Url} from "../../constants/global";

import Pagination from "../Pagination/Pagination";
import TableDeadLetterDropdown from "components/Dropdowns/TableDeadLetterDropdown.js";
import {toast} from "react-toastify";

class DeadLetterTable extends Component {

  state = { totalDeadLetters: 0, currentDeadLetters: [], currentPage: null, totalPages: null }

  getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  }

  componentDidMount() {
    fetch(Url + '/backend/deadletter', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    })
        .then(res => res.json())
        .then(
            (data) => {
              this.setState({totalDeadLetters: data.max, currentDeadLetters: data.results});
            }
        )
  }

  onPageChanged = data => {
    const { currentPage, totalPages, pageLimit } = data;

    fetch(Url + '/backend/deadletter?page='+currentPage+'&limit='+pageLimit, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      }
    }).then(res => res.json())
      .then(
          (results) => {
            this.setState({ currentPage, totalDeadLetters: results.max, currentDeadLetters: results.results, totalPages });
          }
      )
  }

  resendClick = (item) => {
    fetch(Url + '/backend/deadletter/'+item.id+'/replay', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.getToken()
      },
      body: '{}'
    }).then(res => res.json())
    .then(
        () => {
          toast.success('Dead Letter Requeued !');
        }
    )
  }

  render() {
    const { totalDeadLetters, currentDeadLetters } = this.state;

    if (totalDeadLetters === 0) return null;
    return (
        <>
          <div
              className={
                  "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                  (this.props.color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
              }
          >
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <h3
                      className={
                          "font-semibold text-lg " +
                          (this.props.color === "light" ? "text-blueGray-700" : "text-white")
                      }
                  >
                    DeadLetter
                  </h3>
                </div>
              </div>
            </div>
            <div className="block w-full overflow-x-auto">
              {/* Projects table */}
              <table className="items-center w-full bg-transparent border-collapse table-fixed">
                <thead>
                <tr>

                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                  >
                    Date
                  </th>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                  >
                    Exchange
                  </th>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                  >
                    Queue
                  </th>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                  >
                    Error
                  </th>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (this.props.color === "light"
                              ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                  >
                    Content
                  </th>
                  <th
                      className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-right " +
                          (this.props.color === "light"
                              ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }
                  >
                    Action
                  </th>
                </tr>
                </thead>
                <tbody>
                {currentDeadLetters.map((item) => {
                  return (
                      <tr className="whitespace-normal">

                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          {" "}
                          <span
                              className={
                                  "ml-3 font-bold " +
                                  +(this.props.color === "light" ? "text-blueGray-600" : "text-white")
                              }
                          >
                    {item.date}
                  </span>
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                          {item.exchange}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {item.queue}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                          {item.error}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace normal p-4">
                          {item.content}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                          <TableDeadLetterDropdown onResendClick={this.resendClick} item={item}/>
                        </td>
                      </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex flex-row py-4 align-items-center">
            <Pagination totalRecords={totalDeadLetters} pageLimit={25} pageNeighbours={1} onPageChanged={this.onPageChanged} />
          </div>
        </>
    );
  }
}

DeadLetterTable.defaultProps = {
  color: "light",
};

DeadLetterTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

export default DeadLetterTable;