import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Url} from "../../constants/global";

function loginUser(credentials) {
  return new Promise((resolve, reject)=>{
    fetch(Url + '/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    }).then((data)=>{
      if(data.status === 200){
        data.json().then((json)=>{
         resolve(json);
        })
      }else{
        data.json().then((json)=>{
          reject(json.message);
        })
      }
    })
  });
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [alert, setAlert] = useState('');

  useEffect(() => {
    if(alert) {
      setTimeout(() => {
        setAlert('');
      }, 3000)
    }
  }, [alert])

  const handleSubmit = (e) => {
    e.preventDefault();
    loginUser({
      username,
      password
    }).then((token)=>{
      setToken(token);
    }).catch((error)=>{
      setAlert(error);
    })
  }

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
              className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-blueGray-600 text-center text-xl mb-3 font-bold">
                      <h1>Sign in to APNF System</h1>
                    </div>
                    {alert!=='' && <div className="text-red-500 text-center text-xl mb-3">{alert}</div>}
                    <form onSubmit={handleSubmit}>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Login
                        </label>
                        <input
                          type="text"
                          name={"username"}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Login"
                          onChange={e => setUserName(e.target.value)}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name={"password"}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};