import React from "react";

import UserTable from "../../components/User/UserTable";

export default function Users() {
  return (
      <>
          <div className="flex flex-wrap mt-4">
              <div className="w-full mb-12 px-4">
                  <UserTable />
              </div>
          </div>
      </>
  );
}
