import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import Login from './views/auth/Login';
import Admin from "./layouts/Admin";
import useToken from "./components/App/useToken";
import {ToastContainer} from "react-toastify";

function App() {
    const { token, setToken } = useToken();

    if(!token) {
        return <Login setToken={setToken} />
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/admin" component={Admin} />
                <Redirect from="*" to="/admin" />
            </Switch>
            <ToastContainer />
        </BrowserRouter>
    );
}

export default App;